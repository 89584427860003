.who-we-support{
    padding-bottom: 100px;
}
.why-flip {
    padding: 80px 70px 20px 50px !important;
}

.premium-color{
    background: #002726;
    border-radius: 50px;
    padding: 25px 30px 20px 30px;
}
.services-sect{
   background-image: url("../Assets/images/special-services1.png");
   background-repeat: no-repeat;
   background-position: top right;
}
.rapid-solution-img{
    background-image: url("../Assets/images/courier-service.png");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px;
}
.effortless-img-background{
    background-image: url("../Assets/images/effortless-img.png");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px;
}
.premium-performance{
    font-size: 24px;
}
.freight-logistics {
    background: #B2DD3C;
}
.transperency{
    padding: 28px 23px 22px 27px;
}
.rapid-solution{
    font-size: 48px;
    font-weight: 500;
}
.whosupport-content{
    background: #002726;
    color: #FFFFFF;
    border-radius: 0px 0px 15px 15px;
    padding: 30px 20px 60px 20px;
}
.whowesupport-img{
    border-radius: 15px 15px 0px 0px;
}
.whowesupport-box{
    border: 0px;
}
/* .effort{
    padding: 0px 0px 20px 0px ;
} */
.why-flip-background{
    background-image: url("../Assets/images/whyflip2.png");
    min-height: 700px;
    background-repeat: no-repeat;
    background-size: cover;
}
/* Mobile view */
@media (max-width: 575.98px) {
    .services-sect{
        background-image: none;
    }
    .why-flip {
        padding: 25px 20px 25px 20px !important;
    }
    .who-we-support {
        padding-bottom: 30px;
    }
    .rapid-solution-img {
        background-image: none;
        min-height: 0px;
    }
    .effortless-img-background{
        background-image:none;
        min-height: 0px;
    }
}

/* Tab view */
@media (min-width: 576px) and (max-width: 992px) {
    .services-sect{
        background-image: none;
    }
    .rapid-solution-img {
        background-image: none;
        min-height: 0px;
    }
    .effortless-img-background{
        background-image:none;
        min-height: 0px;
    }
   .why-flip {
    padding: 25px 20px 25px 30px !important;
}
    
}
@media (min-width:993px){
   /* .why-flip {
    padding: 25px 20px 25px 30px !important;
} */
}