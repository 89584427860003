body {
    font-family: "Poppins";
    overflow-x: hidden;
}
.logistic-sect{
    background-image: url("../Assets/images/logistics-main1.png");
    background-repeat: no-repeat;
    background-position: top right;
}

.efficient-head {
    font-weight: 600;
}
.efficient-sub{
    font-weight: 500;
}

/* .account-management {
    padding-bottom: 40px;
} */
.efficient-warehouse{
    padding-bottom: 100px ;
}
.insurance-shade{
    width: 50px;
    height: 50px;
    margin: 0px 0px -12px 18px;
}
.comprehensive{
    margin: 50px 160px 0px 160px ;
}
.comprehensive-img-background{
    background-image: url('../Assets/images/comprehensive.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 535px;
}
.industry-solution{
    padding: 80px 0px 90px 0px ;
}
/* Mobile view */
@media (max-width: 575.98px) {
   
    .comprehensive{
        margin:0px ;
    }
    .logistic-sect{
        background-image: none;
    }
    .rapid-solution {
        font-size: 30px !important;
    }
    .efficient-warehouse {
        padding-bottom: 20px !important;
    }
    .comprehensive-img-background{
        background-image: none;
        min-height: 0px;
    }
}

/* Tab view */
@media (min-width: 576px) and (max-width: 992px) {

    .efficient-warehouse {
        padding-bottom: 40px;
    }
    .comprehensive{
        margin:0px ;
    }
    .rapid-solution {
        font-size: 30px !important;
    }
    .logistic-sect{
        background-image: none;
    }
    .comprehensive-img-background{
        background-image: none;
        min-height: 0px;
    }
    .industry-solution{
        padding:  0px !important ;
    }
    .industry-reponsive {
        padding: 30px 0px;
    }
}