.scroll-container disabled{
    cursor: not-allowed;
  } 
      .scroll-indicators {
        position: absolute;
        bottom:0 ;
      margin-top: 30px;
        background-color: #666;
        height: 8px;
        border-radius: 4px;
        transition: transform 0.3s ease;
  
        cursor: pointer;
        display: -webkit-inline-box;
      }
  
      .scroll-indicator {
        height: 100%;
        width: 20px; 
        margin-bottom: 4px; 
        background-color: #666;
        border-radius: 4px;
  
      }
  
   .scroll-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #9ACE21;
      height: 8px;
      border-radius: 4px;
      cursor: pointer;
    /* Initially, the scroll bar is hidden */
      transition: width 0.3s ease;
      min-width: 10px!important;
    }
  
  .scroll-bar-container{
    display: inline-block;
    width: 50px;
    height: 8px;
    position: absolute;
    bottom: 0;
      background-color:#002726;
      border-radius: 6px;
  }
  
  
  .scroll-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .scroll-content {
      width: 100%;
        height: 100%;
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    transition: transform 0.3s ease;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
  
  .scroll-button {
    position: absolute;
    top: 44%;
    transform: translateY(-50%);
    font-size: 24px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    padding: 10px;
    transition: background-color 0.3s ease;
    z-index: 998;
  }
  
  .scroll-button:hover {
    background-color: #ddd;
  }
  
  .left-button {
    left:0px;
  }
  
  .right-button {
    right:0px;
  }
  
  
  .scroll-content::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    display: none;
  
    border-radius: 6px; /* Adjust the radius to match the scrollbar width */
    width: 20%; /* Adjust the width as needed */
    height: 8px; /* Adjust the height as needed */
  }
  /* Add this CSS to style the scrollbar */
  .scroll-content::-webkit-scrollbar {
    width: 20%; /* Adjust the width as needed */
    height: 8px; /* Adjust the height as needed */
    display: none;
  }
  
  .scroll-content::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
    display: none;
  }
  
  .scroll-content::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    display: none;
  }
  
  .scroll-item .col-product-1-shop, .scroll-item .col-first-dark{
    width: 260px;
  }
  
  .scroll-item .col-category{
    margin-left: 0;
  }
  
  
  #scroll-content3 .item{
    width: 500px;
    padding: 30px;
    background-color: #fff;
  }
  
  #scroll-content1{
    padding-bottom: 50px;
  }
  
  #scroll-content3{
    padding-bottom: 40px;
  }
 .testimonial-scroll{
  bottom: 22px;
 }
/* Mobile view */
@media (max-width: 575.98px) {
   

 .testimonial-scroll {
  bottom: 10px;
}
}
