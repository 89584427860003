.login-head {
    font-size: 34px;
    font-weight: 600;
    background-image: url("../Assets//images/track-dotted.png");
    background-repeat: no-repeat;
    padding-top: 20px;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,700&display=swap');
.login-para {
    font-size: 20px;
    font-weight: 600;
}
.login-sect{
    /* background-image: url("../Assets//images//login-xp.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; */
}
.login-img {
    background-image: url("../Assets//images//login-xp.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.login-text-content {
    font-size: 30px;
    color: white;
    font-family: "Poppins", ital;
    text-align: center;
}

.login-text2 {
    font-size: 20px;
    float: right;
}

.login-content-img {
    padding: 200px 80px;

}

.login-content-img {
    padding: 492px 80px 0px 134px;
}

/* @font-face {
    font-family: '  Poppins-bold-italic';
    src: url('../Assets/fonts/Poppins-BoldItalic.ttf') format(truetype);
  }
  @font-face {
    font-family: '  Poppins-bold-italic1';
    src: url('../Assets/images/Poppins-BoldItalic.ttf') format(truetype);
  }
  
  .texts-poppins-bold {
    font-family: Poppins-bold-italic1 !important;
  } */

/* Mobile view */
@media (max-width: 575.98px) {
    .login-content {
        padding: 28px;
    }
    .login-content-img {
        padding: 100px 50px;
    }
    .login-text-content {
        font-size: 20px;
    }
}

/* Tab view */
@media (min-width: 576px) and (max-width: 992px) {
    .login-content {
        padding: 28px;
    }

    .login-content-img {
        padding: 200px 50px;
    }
    .login-text-content {
        font-size: 20px;
    }
}