.name-form{
    border: 1px solid #E2E2E2;
    color: black;
    width: 90%;
}
.name-form:focus-visible{
   outline: none;
}
.message-form{
    height: 136px;
}
input[type="text"]::placeholder {
     top: 0px;
  }

/* Mobile view */
@media (max-width: 575.98px) {
  .whowe-support {

  }
  .who-support {
   
    border-radius: 0px;
}
}

  /* Tab view */
@media (min-width: 576px) and (max-width: 992px) {

}