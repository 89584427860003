body {
    font-family: "Poppins";
    overflow-x: hidden;
}
.company-content {
    background-image: url("../Assets/images//express-dotted.svg");
    background-repeat: no-repeat;
    padding-right: 100px;
}

.our-company {
    color: #002726;
    font-size: 75px;
    font-weight: bold;
    line-height: 1;
}
.company-para{
    margin-top: 25px;
}

.essence {
    color: #9ACE21;
}

.company-main {
    margin: 100px 0px 0px 0px;
    padding: 0px 0px 90px 0px;
}

.ceo {
    font-size: 50px;
}

.leniency {
    background-color: #B2DD3C;
}
.leniency-img{
    padding-left: 180px;
}
.leniency-content {
    background-image: url("../Assets/images/about-revolution-dotted.png");
    background-repeat: no-repeat;
}

.leniency-head {
    color: #000000;
    font-size: 50px;
    font-weight: bold;
}
.revolution-img{
    padding-right: 160px;
}
.revolution-head {
    color: #FFFFFF;
}

.revolution {
    background-color: #002726;
}

.testimonial {
    background-color: #FAFAFA;
}

.revolution-content {
    color: white;
}

.testimonial-head {
    color: #000000;
}

.testimonial-path {
    background-image: url("../Assets/images/testimonial-path.svg");
    background-repeat: no-repeat;
    background-position: 10px 13px;
    background-size: 30px;
    width: 350px;
}

.jordan {
    font-weight: 600;
}
/* .ceoimg{
    margin-top: -132px;
} */
.about-company{
    margin-top: 100px;
}
.ceo-mantis {
    color: #9ACE21;
    font-size: 13px;
}
.ceo-content {
    padding-left: 54px;
}

.carousel-control-prev-icon {
    background-image: url("../Assets/images/left-slide.png");
}

.carousel-control-next-icon {
    background-image: url("../Assets/images/right-slide.png");
}

.carousel-control-prev-icon {
    margin-left: -20px;
    height: 17px;
    width: 10px;
}

.carousel-control-next-icon {
    height: 17px;
    width: 10px;
}
.carousel-control-prev {
    left: -120px;
}
.carousel-control-next {
    right: -120px;
}
.carousel-indicators {
    display: none;
}

.content-slide {
    padding: 10px 0px 50px 0px;
}
.testimonials-content{
    padding: 0px;
}

/* Mobile view */
@media (max-width: 575.98px) {

    .our-company {
        font-size: 36px;
        margin-top: 25px;

    }
    .company-para {
        margin-left: 16px;
    }
    .about-company{
        margin-top: 0px;
    }
    .company-content{
        padding-right: 0px;
        background-image: url("../Assets/images/company-newessence-responsive.svg");
        background-position: -4px 0px;
    }
    .ceo-content{
        padding-left: 0px;
    }
    .company-main {
        margin: 0px 0px;
        padding: 50px 0px ;
    }
    .leniency-img{
        padding-left: 0px;
    }
    .leniency-content {
        padding-top: 30px;
        background-image: url("../Assets/images/leninency-dotted-responsive.svg");
        background-repeat: no-repeat;
        
    }
    .revolution-img{
        padding-right: 0px;
    }
    .leniency-head {
        font-size: 30px;
    }
    .testimonial-content {
        padding-bottom: 20px;
    }

    .testimonials-content{
        padding: 40px 0px;
    }

    .content-slide {
        padding: 20px 0px;
    }

    .our-goals-number {
        height: 400px;
        background-size: 500px 200px;
    }
    .carousel-control-prev {
        left: 0px;
    }
    .carousel-control-next {
        right: 0px;
    }
    .ceoimg{
        margin-top: 0px;
    }
}

/* Tab view */
@media (min-width: 576px) and (max-width: 992px) {
    .about-company {
        margin-top: 0px;
    }
    .our-company {
        font-size: 40px;
    }
    .carousel-control-next {
        right: -62px;
    }
    .carousel-control-prev {
        left: -62px;
    }
    .revolution-img{
        padding-right: 0px;
    }
    .leniency-img{
        padding-left: 0px;
    }
    .company-main {
        margin: 0px 0px;
        padding: 50px 0px ;
    }
    .company-content {
        padding-right: 0px;
    }
    .ceo-content {
        padding-left: 0px;
        padding-top: 40px;
    }
    .ourgoals {
        padding: 20px 0px;
    }
}