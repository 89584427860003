.login-button{
    background: #9ACE21;
    width: 100%;
}
.login-text{
    padding: 15px;
    border: 0px;
    color: white;
    font-size: 20px;
    font-weight: 600;
}
.login-form{
    padding: 20px 100px 0px 0px;

}
.forgot-password{
    float: right;
    font-weight: 600;
}
.login-form{
    width: 100%;
}
.sign-up{
    color: #002726;
    font-weight: bold;
    border: none;
    background: none;
}
.field-icon {
    float: right;
    margin-right: 27px;
    margin-top: -41px;
    position: relative;
    z-index: 2;
}

.industry-solution{
    padding: 80px 0px 100px 0px;
}
/* Mobile view */
@media (max-width: 575.98px) {
    .login-form {
        padding: 0px;
 
    }

}

/* Tab view */
@media (min-width: 576px) and (max-width: 992px) {

    .login-form {
        padding: 0px;
 
    }
   
}
