.signup-icon{
    float: right;
    margin-right: 27px;
    margin-top: -57px;
    position: relative;
    z-index: 2;
}
.signup-form{
    padding: 20px 100px 0px 0px;
}

/* Mobile view */
@media (max-width: 575.98px) {
  
    .signup-form{
        padding:  0px;
    }
}

/* Tab view */
@media (min-width: 576px) and (max-width: 992px) {
   
    .signup-form{
        padding:  0px;
    }
}