@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px !important;
  }
}
body {
  font-family: "Poppins";
  overflow-x: hidden;
}
.express{
  
}

.home-main {
  margin-top: -21px;
}

/* .video{
    width: 100%;
    height: 400px;
    background: url("../Assets//video/homevideo.mp4");
} */
/* .flipvideo {
    top: 95px;
    left: 0px;
    width: 100%;
    height: 500px;

} */
.flip {
  position: relative;
  width: 100%;
  /* height: 600px; */
  height: 60vh;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
video {
  position: absolute;
  top: 0;
  left: 0;
  height: 60vh;
  object-fit: cover;
}

.track-id {
  background: #002726;
  padding: 50px;
}

.track-search {
  background: #9ace21 !important;
  color: white;
  padding: 0px 50px 0px 50px;
  font-size: 24px;
  border-radius: 0px;
}

.shipping {
  color: #ffffff;
  font-size: 60px;
  padding: 0px 0px 14px 0px;
}

.track-form {
  padding: 80px 0px 30px 0px;
}

input[type="search"] {
  padding: 0px 20px 0px 20px;
  color: #c1c1c1;
  height: 80px;
  font-size: 24px;
  box-sizing: border-box;
  border-radius: 0px;
}

.ecommerce-image {
  position: relative;
  object-fit: cover;
  width: 480px;
  height: 747px;
}

.shipping-rates {
  font-size: 20px;
  font-weight: 600;
}

.company-essence-background {
  background-image: url("../Assets/images/company-essance.png");
  min-height: 500px;
}
.ecommerce-head {
  position: absolute;
  color: #ffffff;
  font-size: 30px;
  margin: 118px 0px 0px 32px;
}

.e-commerce .e-commerce-shadow {
  height: 272px;
}

.logistic-main {
  margin-top: 30px;
}

.e-commerce-main:hover .logistics-shadow {
  height: 100%;
}

.logistics:hover .logistics-shadow {
  height: 100%;
}

.express:hover .logistics-shadow {
  height: 100%;
}

.freight:hover .logistics-shadow {
  height: 100%;
}
.freight-log-side {
  background: #b2dd3c;
}
.logistics-shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #9ace21;
  overflow: hidden;
  height: 0;
  transition: 0.5s ease;
}
.faster {
  justify-content: center;
}
.logistic-head {
  color: #ffffff;
  font-size: 36px;
  font-weight: 600;
}

.logistic-text {
  color: #ffffff;
  font-size: 18px;
}

.imglogic {
  margin-top: 0px;
}

.logistic-img {
  margin-top: 80px;
}

.e-commerce-shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%);
  overflow: hidden;
  height: 0;
  transition: 0.5s ease;
}

.ecommerce-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 200px;
  left: 40px;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
}

.fast {
  font-size: 20px;
}

.fast-icon {
  background: #ffffff;
}

.company-essance {
  background-image: url("../Assets//images//imgcompany.png");
  background-repeat: no-repeat;
  background-position: 9px 0px;
  /* padding-left: 25px; */
}

.company {
  padding: 100px 0px;
}

.companies {
  font-size: 74px;
  font-weight: bold;
}

.essance {
  color: #9ace21;
}

.read-more {
  padding: 10px 15px 10px 15px;
  font-size: 18px;
  font-weight: 500;
  border: none;
}

.read {
  border: 2px solid #ffffff;
  background: none !important;
}

.btn:hover {
  color: #ffffff;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.dabba {
  font-size: 80px;
  font-weight: bold;
  line-height: 0.7;
}

.launch {
  background-color: #b2dd3c5c;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.launching {
  font-size: 40px;
  color: #000000;
  font-weight: medium;
  background-image: url("../Assets/images/launching-dot.png");
  background-repeat: no-repeat;
  padding-top: 27px;
}

.launching-content {
  font-size: 30px;
  color: #000000;
  font-weight: medium;
}

.fxp-express {
  background: #002726;
}

.fxpexpress {
  width: 100%;
  margin-top: 70px;
}

.fxp {
  color: #ffffff;
  font-weight: bold;
  text-align: left;
  font-size: 60px;
}

.fxpfreighttop {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.freight-logistics {
  /* background: #B2DD3C;
    padding-bottom: 19px; */
}

.fxp-express-dot {
  background-image: url("../Assets/images/fxp-express-dot.png");
  background-repeat: no-repeat;
  background-position: top right;
}

.freight-logistic-dot {
  background-image: url("../Assets/images/fxp-express-dot.png") !important;
  background-repeat: no-repeat !important;
  background-position: top left !important;
}

.our-goals-number {
  background-image: url("../Assets/images/goal-map.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 500px;
}

.goals {
  position: relative;
}

.goals-content {
  margin-top: 200px;
}

.goals-count {
  font-weight: 600;
  font-size: 42px;
}

.deliver-content {
  font-size: 20px;
}

.freight-logistic-content {
  padding-left: 30px;
}

.customer-services {
  font-size: 50px;
  font-weight: bold;
}
.customer-sect {
  padding: 70px 0px;
}
.ourgoals {
  padding: 30px 0px;
}

.goalsnum {
  padding: 20px 0px;
}

.shipping-rates-content {
  padding-top: 30px;
}

/* Mobile view */
@media (max-width: 575.98px) {
  .track-id {
    padding-top: 24px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .login {
    margin: 0px 20px 20px 20px;
  }
  .fast {
    font-size: 16px;
  }
  .imglogic {
    height: 40px;
  }
  .fxp-express-view {
    padding: 40px 30px 0px 28px;
  }

  .shipping {
    font-size: 24px;
    padding: 0px 0px 0px 0px;
  }

  input[type="search"] {
    font-size: 15px;
    height: 50px;
    padding: 10px;
  }
  .flip {
    /* height: 200px; */
  }

  .track-search {
    font-size: 15px;
    padding: 10px;
  }

  .track-form {
    padding: 0px 0px 20px 0px;
  }
  .faster {
    justify-content: left;
  }

  .companies {
    font-size: 30px;
    padding-top: 31px;
  }

  .company {
    padding: 44px 0px;
  }
  .company-essance {
    padding-left: 0px;
    background-position: 0px 10px;
    background-image: url("../Assets/images/company-essance-responsive.svg");
  }

  .fxp {
    font-size: 30px;
  }

  .launching {
    font-size: 30px;
  }

  .dabba {
    font-size: 40px;
  }

  .launching-content {
    font-size: 15px;
    padding-top: 20px !important;
  }

  .Launchin-view {
    /* padding-top: 25px; */
    /* padding-left: 16px; */
  }

  .customer-services {
    font-size: 25px;
    /* padding: 40px 0px; */
  }

  .ship-rate {
    padding-bottom: 16px;
  }

  .ourgoals {
    padding: 15px 0px;
  }
  .goals-content {
    margin-top: 30px !important;
  }
  .goalsnum {
    padding: 20px 0px;
  }

  .ecommerce-image {
    height: 430px;
  }
  .ecommerce-text {
    top: 220px;
    left: 25px;
    font-size: 22px;
  }
  .freight-logistic-content {
    padding: 40px 30px 0px 28px;
  }

  .deliver-complete {
    padding-bottom: 20px;
  }

  .goals-count {
    font-size: 22px;
  }

  .deliver-content {
    font-size: 18px;
  }
  .freight-logistic-dot {
    background-position: top right !important;
  }
  .logistic-img {
    margin-top: 20px;
    height: 50px;
  }

  .logistic-main {
    font-size: 40px;
    margin-top: 20px;
  }

  .logistic-head {
    font-size: 22px;
  }

  .logistic-text {
    font-size: 12px;
  }

  .shipping-rates-content {
    padding-top: 10px;
  }
  .customer-sect {
    padding: 40px 0px;
  }
}

/* Tab view */
@media (min-width: 576px) and (max-width: 992px) {
  .trackbutton {
    margin: 0px 0px 0px 20px;
  }
  .track-form {
    padding: 30px 0px 30px 0px;
  }

  .logo-main {
    margin-left: 0px;
  }
  .flip {
    height: 500px;
  }
  .shipping {
    font-size: 30px;
  }

  .freight-logistic-dot {
    background-position: top right;
  }
  /* .track-form {
        padding: 0px 0px 20px 0px;
    } */

  .shipping {
    padding: 0px 0px 30px 0px;
  }
  .companies {
    font-size: 40px;
    padding-top: 31px;
  }
  .company-essance {
    padding-left: 0px;
  }
  .customer-sect {
    padding: 40px 0px;
  }
  .freight-logistic-dot {
    background-position: top right !important;
  }
  .company {
    padding: 70px 0px;
  }
  .faster {
    justify-content: left;
  }

  .fxp {
    font-size: 40px;
    padding: 20px 0px;
  }

  .fxp-express-view {
    padding: 40px 0px 0px 55px;
  }

  .freight-logistic-content {
    padding: 40px 0px 0px 55px;
  }

  .dabba {
    font-size: 40px;
  }

  .launching-content {
    font-size: 20px;
  }

  .customer-services {
    font-size: 40px;
    /* padding: 50px 0px; */
  }

  .ship-rate {
    padding-bottom: 40px;
  }

  .shipping-rates-content {
    padding-top: 10px;
  }

  .goals-content {
    margin-top: 150px;
  }

  .goals-count {
    font-size: 20px;
  }

  .deliver {
    padding-top: 40px;
  }

  .our-goals-number {
    height: 300px;
  }

  .goals-content {
    margin-top: 100px;
  }
  .fast {
    margin-bottom: 20px;
  }
}
@media (max-width: 992px) {
  .company-essence-background {
    background-image: none;
    min-height: 0px;
  }
}
@media (min-width: 993px) and (max-width: 1288px) {
  .fxp {
    font-size: 40px !important;
  }
}
@media (min-width: 1900px) {
  .ecommerce-image {
    width: 1119px;
  }
}
