.links-head {
  font-size: 24px;
  font-weight: 600;
}

.links-footer {
  font-size: 18px;
  color: #fafafa;
  cursor: pointer;
}

.get-direction {
  border: 2px solid #ffffff;
  color: #ffffff;
  border-radius: 0px;
}

.copy {
  border-top: 1px solid #80808099;
  font-size: 12px;
}

.footer-sect {
  background-color: #002726;
}

.videoPlayer {
  min-height: 400px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.footer-width {
  margin: 0px 160px;
}

.copyright-content-1 {
  text-align: left;
  margin-top: 24px;
}

.copyright-content-2 {
  text-align: right;
  margin-top: 24px;
}

/* Mobile view */
@media (max-width: 575.98px) {
  .footer-width {
    margin: 0px 0px;
  }
  .copyright-content-1 {
    text-align: center;
  }
  .copyright-content-2 {
    text-align: center;
    margin-top: 0px;
  }
}

/* Tab view */
@media (min-width: 576px) and (max-width: 992px) {
  .footer-width {
    margin: 0px 0px;
  }
  .copyright-content-1 {
    text-align: center;
  }
  .copyright-content-2 {
    text-align: center;
    margin-top: 0px;
  }
}
