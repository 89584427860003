body {
    font-family: "Poppins";
    overflow-x: hidden;
}

.nav-bar {
    background-color: #002726;
    font-size: 14px;
    font-family: "Poppins";
}



.navbar-dark .navbar-nav .nav-link {
    color: #FFFFFF;

}



.login {
    border: 1px solid #B2DD3C;
    border-radius: 20px;
    /* margin: 0px 20px; */
    font-size: 14px;
    font-weight: normal;
    background-color: #9ACE21;
    color: #FFFFFF;
}

.btn-light:hover {
    background-color: #002726;
    color: none;
}


.trackbutton {
    background-color: #002726;
    color: #FFFFFF;
    box-shadow: 0px 10px 15px #00000014;
    border: 1px solid #B2DD3C;
    border-radius: 20px;
    margin: 0px 20px;
    font-size: 14px;
    font-weight: normal;
}
.logo-main{
    /* margin-left: 150px; */
}
/* .navbar-dark .navbar-nav .nav-link .active {
    color:  #9ACE21;
}
.navbar-dark .navbar-nav .nav-link:hover {
    color:  #9ACE21;
} */
.track-login{
    /* padding-left: 200px; */
}
a{
    color: white;
}
a.active{
    color:#9ACE21;
}
a:hover{
    color:#9ACE21;
    text-decoration: none;
}

/* Mobile view */
@media (max-width: 575.98px) {
    .home{
        padding-bottom: 10px;
    }
    .track-login{
        padding-left: 0px;
    }
    .trackbutton {
        margin: 20px 20px;
    }
    .login{
        margin: 0px 20px 20px 20px;
    }
    .logo-main{
        margin-left: 0px;
    }
    .ceoimg {
        margin-top: 0px;
    }

    
}


/* Tab view */
@media (min-width: 576px) and (max-width: 992px) {
    .home{
        padding-bottom: 15px;
    }
    .track-login {
        padding-left: 0px;
    }
    .login{
    margin: 20px 0px 20px 20px;
    }
}

@media (min-width: 993px) and (max-width:1288px)  {
   .home{
    padding-left: 0px !important;
   }
}

