body {
  font-family: "Poppins";
  overflow-x: hidden;
}

.express-sect {
  background-image: url("../Assets/images/safe-reliable-img1.png");
  background-repeat: no-repeat;
  background-position: top right;
}

.international-img-background {
  background-image: url("../Assets/images/interntional-services.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 634px;
}

.safe-reliable {
  margin-left: 0px;
}

.safe-head {
  padding: 10% 0%;
}

.safe-content {
  background-image: url("../Assets/images//express-dotted.svg");
  background-repeat: no-repeat;
  padding-top: 27px;
}

.reliable-head {
  font-size: 50px;
  font-weight: 600;
}

.international-main {
  padding: 0px 0px 100px 0px;
}

.safe-reliable-content {
  line-height: 1.2 !important;
}

.industry-solution-readmore {
  background-color: white;
  color: #9ace21;
  border-radius: 0px;
  border: 2px solid #9ace21;
  padding: 10px 15px 10px 15px;
  /* font-size: 15px; */
  font-weight: 500;
}

.industry-solution-readmore:hover {
  color: #9ace21 !important;
}

.industry-img {
  width: 100% !important;
  height: 405px !important;
}

.industry-content {
  position: relative;
}

.industry-text {
  font-size: 28px !important;
  top: 180px !important;
}

.check-rates {
  background-color: #9ace21;
  color: white;
  border-radius: 0px;
  padding: 10px 15px 10px 15px;
  /* font-size: 15px; */
  font-weight: 500;
}

.domestic-head {
  font-size: 20px;
  font-weight: 600;
}

.domestic-content {
  padding: 30px 30px 30px 0px;
}

.domestic-shade {
  height: 40px;
  width: 40px;
  background-color: #9ace2167;
  border-radius: 30px;
  /* margin-left: 37px; */
  margin: 0px 0px 0px 42px;
  position: relative;
}

.domestic-icon-img {
  position: absolute;
  margin-top: -39px;
}

.collection-shadow {
  margin: 0px 0px 0px 19px;
}

.payment-shadow {
  margin: 0px 0px 0px 19px;
}

.track-shade {
  margin: 0px 0px 0px 19px;
}

.value-added-service {
  background-color: #002726;
}

.value-added-head {
  color: white;
}

.value-added-para {
  color: white;
  font-size: 30px;
}

.value-added-touch {
  border: 2px solid white;
  border-radius: 0px;
  font-weight: 500;
  color: white;
  padding: 10px 15px;
}

.value-added-service-content {
  padding: 70px 0px 70px 0px;
}

.trends-content {
  font-size: 24px;
  font-weight: 600;
}

.trend-card {
  border: 1px solid #ededed;
  border-radius: 0px;
}

.trend-card2 {
  border-left: 1px solid #ededed !important;
}

.latest-trend {
  border-radius: 0px;
}

.content-trends {
  border-top: 0px;
  font-size: 18px;
  background: none;
}

.domestic-img-background {
  background-image: url("../Assets//images/domestic-services.png ");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 677px;
}
.industry-reponsive {
  padding: 100px 0px 0px 0px;
}

.domestic {
  padding: 90px 30px 60px 0px;
}

/* Mobile view */
@media (max-width: 575.98px) {
  .safe-content {
    padding-left: 0px;
    padding-top: 0.2px;
    background-position: top left;
    background-image: url("../Assets/images/express-dotted-responsive.png");
  }

  .express-sect {
    background-image: none;
  }

  .safe-reliable {
    margin-left: 0px;
  }

  .domestic {
    padding: 0px;
  }

  .safe-head {
    padding: 0% 0% 0% 5%;
  }

  .reliable-head {
    font-size: 30px;
  }

  .domestic-img-background {
    background-image: none;
    min-height: 0px;
  }

  .industry-text {
    font-size: 20px !important;
    top: 213px !important;
  }

  .domestic-content {
    padding: 0px;
  }

  .check-rates {
    margin: 40px 0px;
  }

  .value-added-para {
    font-size: 20px;
  }

  .value-added-service-content {
    padding: 40px 0px;
  }

  .international-main {
    padding: 0px 0px 0px 16px !important;
  }

  .domestic-check {
    margin: 40px 0px 0px 0px !important;
  }

  .domestic-main {
    padding: 20px 0px !important;
  }

  .international-img-background {
    background-image: none;
    min-height: 0px;
  }
  .industry-solution {
    padding: 0px !important;
  }
  .industry-reponsive {
    padding: 0px;
  }
}

/* Tab view */
@media (min-width: 576px) and (max-width: 992px) {
  .safe-head {
    padding: 0%;
  }

  .safe-content {
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .safe-reliable {
    margin-left: 0px;
  }

  .express-sect {
    background-image: none;
  }

  .reliable-head {
    font-size: 30px;
  }

  .industry-text {
    font-size: 20px !important;
    top: 213px !important;
  }

  .value-added-service-content {
    padding: 40px 0px;
  }

  .value-added-para {
    font-size: 20px;
  }

  .international-main {
    padding: 0px 0px !important;
  }

  .domestic-check {
    margin: 40px 0px 40px 0px !important;
  }

  .domestic-content {
    padding: 40px 30px 0px 0px;
  }

  .international-img-background {
    background-image: none;
    min-height: 0px;
  }

  .domestic-img-background {
    background-image: none;
    min-height: 0px;
  }
}
