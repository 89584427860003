body {
    font-family: "Poppins";
    overflow-x: hidden;
}
.contact-location{
    /* padding: 0px 194px; */
}
.location-shade{
    height: 45px;
    width: 45px;
    margin: -23px 0px -43px 18px;
    position: static;
}
.contact-sect{
    background-image: url("../Assets/images/contact-img1.png");
    background-repeat: no-repeat;
    background-position: top right ;
}
.contact-card{
    border: none;
}
.contact-location-section{
    padding: 100px 0px;
}
.contact-loc{
    font-size: 24px;
}
.contact-customer {
    padding: 50px 0px;
}


/* Mobile view */
@media (max-width: 575.98px) {
    .contact-location{
        padding: 0px ;
    }
    .contact-location-section {
        padding: 40px 0px;
    }
    .contact-sect{
        background-image: none;
    }
    .contact-customer {
        padding: 40px 0px;
    }
    .name-form {
        width: 100% !important;
    }

}

/* Tab view */
@media (min-width: 576px) and (max-width: 992px) {

    .contact-location{
        padding: 0px ;
    }
    .contact-location-section {
        padding: 50px 0px;
    }
    .contact-sect{
        background-image: none;
    }
    .contact-customer {
        padding: 30px 0px;
    }
}