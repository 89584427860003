.who-we-support-box{
    padding-top: 30px;
    border-radius: 50px;
    width: 1140px;

}
.who-we-support-content{
   
    color: #FFFFFF;
    padding: 172px 50px;
    
}
.services-scroll{
    margin-left: 10%;
}

.whowe-support{
    background-image: url("../Assets/images/who-we-support-img.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0px 50px 50px 0px;
}
.retailers{
    background-image: url("../Assets/images/retailers-carousal.png");
}
.social-media{
    background-image: url("../Assets/images/social-media-carousal.png");
}
.other-verticals{
    background-image: url("../Assets/images/other-verticals-carousal.png")
}
.who-support{
    background: #002726;
    border-radius: 50px 0px 0px 50px;
}
.e-commerce-text{
    font-size: 34px;
}
.carousel-indicators {
    display: inline !important;
}
.scroll-indicates{
    left: 29%;
    bottom: -33px;
}
.scroll-box{
    width: 10px !important;
    background-color: #9ACE21 !important;
}
.scroll-box2{
    width: 10px !important;
    background-color: #002726 !important;
}

/* Mobile view */
@media (max-width: 575.98px) {
    .who-we-support-box {
         padding: 0px;
         border-radius: 0px;
         /* width: 400px; */
}
.who-we-support-content {
    color: #FFFFFF;
    padding: 50px ;
}
.e-commerce-text {
    font-size: 20px;
}
}

/* Tab view */
@media (min-width: 576px) and (max-width: 992px) {
    .who-we-support-box {
        padding: 0px;
        border-radius: 0px;
}
.who-we-support-content {
   color: #FFFFFF;
   padding: 50px ;
}
.e-commerce-text {
   font-size: 20px;
}
 

}