.track-dotted-img {
  margin-top: -55px;
  margin-left: 340px;
}

.track-dotted {
  background-color: #9ace21;
  height: 160px;
}

.track-search-form {
  margin-top: -43px;
  box-shadow: 0px 0px 26px #00000029;
}

.tracker {
  background-color: #002726;
  color: #ffffff;
  padding: 0px 50px 0px 50px;
  font-size: 24px;
  border-radius: 0px;
}

.half-a-border-on-left {
  padding-top: 30px;
}

.status-box {
  border-left: 1px solid lightgray;
  /* padding: 0px 0px 21px 28px; */
  padding: 20px 0px 0px 28px;
  position: relative;
}

.created-status {
  padding: 0px 0px 0px 28px;
  position: relative;
}

.created {
  font-weight: 600;
}

.checked {
  color: white;
  line-height: 1;
}

.application {
  padding: 30px 0px;
  float: left;
}

.details {
  font-size: 24px;
  font-weight: 600;
}

.date {
  color: #838383;
}

.status-badge {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 40px;
  left: -12px;
  border-radius: 20px;
  background: #9ace21;
  border: 2px solid white;
  padding: 2px;
  z-index: 999;
}

.status-created {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0px;
  left: -12px;
  border-radius: 20px;
  background: #9ace21;
  border: 2px solid white;
  padding: 2px;
}

.order-status {
  border-left: 1px solid lightgray;
  padding: 0px 0px 24px 50px;
  margin: 50px 0px;
}

.status-item {
  border-left: 1px solid lightgray;
  padding: 0px 0px 0px 28px;
  position: relative;
}

.status-head {
  padding-right: 80px;
}

.status-detail-item {
  padding: 0px 0px 10px 0px;
}

.order-main {
  border-left: 0px;
  padding: 0px 70px;
}
.order-detail-content {
  color: #000000;
}

.break {
  color: lightgray;
}

.form-control:focus {
  box-shadow: none;
  border: none;
}

.track-main {
  margin: 50px 160px 0px 160px;
  padding: 0px 0px 90px 0px;
}

.track-main-content {
  margin: 20px 0px;
  padding: 30px 0px 0px 90px;
}
.nav-item{
  display: table-cell;
  width: auto;
}

.track-tabs {
  display: inline-block;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  .nav-link {
    color: #838383;
    font-weight: 500;
  }


  .nav-link.active,
  track-tabs.active {
    color: #000000;
    background-color: #9ace2130;
  }
}

/* Mobile view */
@media (max-width: 575.98px) {
  .tracker {
    font-size: 15px;
    padding: 10px;
  }

  .track-dotted-img {
    margin-top: -55px;
    margin-left: 90px;
  }
  .order-status {
    border-left: 0px;
    padding: 0px 0px 20px 0px;
    margin: 20px 0px;
    padding: 0px 30px;
  }

  .track-main {
    margin: 0px -15px 0px -18px;
    padding: 0px;
  }

  .track-main-content {
    margin: 0px;
    padding: 20px 0px 0px 20px;
  }
}

/* Tab view */
@media (min-width: 576px) and (max-width: 992px) {
  .track-dotted-img {
    margin-top: -55px;
    margin-left: 0px;
  }
  .track-main {
    padding: 40px 0px 40px 0px;
    margin: 0px;
  }
  .order-status {
    margin: 40px 0px;
  }
  .status-head {
    padding-right: 0px;
  }
  .break {
    margin-right: 60px;
  }
}
