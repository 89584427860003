.sign-up-img{
    background-image: url("../Assets//images/signup-xp.png");
}
.signup-content-img{
    padding: 644px 80px 0px 134px;
}
/* Mobile view */
@media (max-width: 575.98px) {
.signup-content {
        padding: 15px;
    }
  
}

/* Tab view */
@media (min-width: 576px) and (max-width: 992px) {
    .signup-content {
        padding: 20px;
    }

  
}